.chatWidget-msg-sharedfilter {
  padding: 0 5px;
  color: #00d25b;
  background-color: #00d25b30;
  cursor: pointer;

  &:hover {
    color: #00dc5f;
  }
}
