.data-section {
  margin-right: 1rem;
  margin-left: 1rem;
}

.data-section-large {
  margin-top: 2em;
}

.data-section-small {
  margin-top: 0.5em;
  margin-right: 1rem;
  margin-left: 1rem;
}

.popular {
  margin-left: 1em;
}

/* .popular-section-wrapper {
  max-height: 400px;
}

@media only screen and (max-width: 576px) {
  .popular-section-wrapper {
    max-height: 250px;
  }
} */

.column-flex {
  flex-direction: column;
}

.static-bar {
  background: #1a1c23;
}
@media only screen and (max-width: 812px) {
  .static-bar {
    margin-right: 5px;
  }
}

.static-img {
  width: 1.5em;
  height: 1.5em;
}

.white-no-wrap {
  white-space: nowrap;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-20 {
  font-size: 20px;
}

.dash-font-color {
  color: #6d7291;
}

.white-color {
  color: white;
}

.img-15 {
  width: 15px;
  height: 15px;
}

.img-30 {
  width: 30px;
  height: 30px;
}

.overflow-scroll {
  overflow: auto;
}

.border-radius-10 {
  border-radius: 10px;
  border: 1px solid gray;
}

.alert-history-color {
  color: rgb(191, 191, 191);
}

.alert-history-separator {
  background: rgb(191, 191, 191);
  height: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
}

@media (max-width: 576px) {
  .alert-history-separator {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.alert-history-data {
  overflow-y: auto;
}

.stream-quote-star {
  position: absolute;
  margin-left: 0.55em;
}
.stream-quote-star.stream-badge-size-sm {
  font-size: 0.75rem;
}

.stockwits {
  position: absolute;
  border-radius: 0 !important;
  width: 13px !important;
  height: 13px !important;
  margin-left: 0.4em;
}
.stockwits.stream-badge-size-sm {
  width: 12px !important;
  height: 12px !important;
}

.bg-low {
  background: #ff0100;
}

.text-white {
  color: white;
}

.stock-active-text {
  color: black;
  padding: 0.1em;
  margin: -0.2em 0;
  border-radius: 0.2em;
  width: 4em;
}

.stock-active-text:hover {
  cursor: pointer;
}

.stock-active-low {
  background: #fc424a;
}

.stock-active-high {
  background: #00d25b;
}

.text-low {
  color: #fc424a !important;
  text-align: center;
}

.bg-high {
  background: #73b101;
}

.text-high {
  color: #00d25b !important;
  text-align: center;
}

.input-border {
  outline: none !important;
  border: 1px solid gray;
  border-radius: 10px;
  font-size: 13px;
}

.table-height {
  height: 400px;
}

.table-scroll {
  overflow: auto;
}

.tableFixHead thead tr {
  display: flex;
  flex-grow: 1;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  flex: 1 1 0;
}

.tableFixHead tbody {
  display: block;
  /* overflow: auto; */
  height: 100%;
}

.tableFixHead tbody tr {
  display: flex;
  position: relative;
}

.tableFixHead tbody td.flex-fill {
  flex: 1 1 0 !important;
}

.discovery-table {
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}

.discovery-table thead th {
  position: sticky;
  top: 0;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  background: #191c24;
}

.bar-icon {
  width: 30px;
  height: 30px;
  background: rgba(108, 114, 147, 0.2);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popover-icon {
  font-size: 20px;
}

.bar-txt {
  margin-left: 1em;
}

.socket-table {
  width: 65%;
}

.popular-table {
  width: 35%;
  padding-left: 15px;
  min-height: 680px;
}

.popular-table:empty {
  display: none !important;
}

.stock-text {
  font-size: 14px;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;
  position: relative;
}

@media only screen and (max-width: 576px) {
  .stock-count {
    margin-left: 10px;
  }
}

.stock-header-text {
  font-size: 14px;
  text-align: center;
}

.petitCarre-inactive {
  width: 7px;
  height: 7px;
  background: gray;
  margin: 2px;
}

.petitCarre-active {
  width: 7px;
  height: 7px;
  background: red;
  margin: 2px;
}

.petitCarre-active-high {
  width: 7px;
  height: 7px;
  background: #73b101;
  margin: 2px;
}

.logo {
  width: 160px;
  text-align: center;
}

.logo h1 {
  font-family: "Lato";
  font-weight: 500;
  letter-spacing: 2px;
  margin: 0;
  font-size: 40px;
}

.logo h2 {
  letter-spacing: 0px;
  font-family: "gotham narrow";
  font-weight: 200;
  font-size: 11px;
  margin: 0;
  white-space: nowrap;
}

.swiper-slide {
  width: 250px !important;
  margin-right: 0px !important;
}

.swiper-container {
  width: 100%;
  margin-top: 0;
  margin-left: 0px !important;
  margin-right: 0px !important;
  z-index: 0 !important;
}

.swiper-pagination-bullet {
  background: white !important;
}

.bullets-section {
  height: 35px;
}

#search-bar-0 {
  outline: none !important;
  border: 1px solid gray;
  border-radius: 10px;
  font-size: 13px;
  height: 34px;
  background: #0d0d0d !important;
  text-align: center;
  color: white;
}

.search-label {
  margin-bottom: 0;
}

.ReactTable {
  margin-top: 15px;
}

.ReactTable .-pagination .-btn {
  background: white;
}

.rt-resizable-header-content {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
}

.rt-td {
  text-align: center;
}

.ReactTable .rt-thead .rt-th {
  padding: 1rem 0.2rem;
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: gray;
}

.stream-btn-set {
  position: absolute;
  right: 0.2em;
  top: -0.2em;
  z-index: 100;
}

.stream-btn-set > .btn {
  font-size: 1.2em;
  cursor: pointer;
}

.btn-max {
  position: absolute;
  right: 0.2em;
  top: -0.2em;
  z-index: 100;
  font-size: 1.2em;
  cursor: pointer;
}

/** Small iPads */
@media only screen and (min-width: 813px) {
  .popular-table.stream-hidden {
    flex-direction: row !important;
  }
}

/** Small iPads */
@media only screen and (max-width: 812px) {
  .socket-table {
    width: 100%;
  }

  .popular-table {
    width: 100%;
    padding-left: 0px;
  }

  .basic-container {
    flex-direction: column !important;
  }
  .basic-container-group-shrink {
    min-height: 420px !important;
  }

  .basic-popular {
    width: 100% !important;
  }

  .tableFixHead {
    padding-left: 0;
    padding-right: 0;
  }

  .stock-text {
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;
    cursor: pointer;
  }

  .rt-resizable-header-content {
    font-size: 11px;
    font-weight: 500;
    text-align: center;
  }
}

/** Large Mobiles */
@media only screen and (max-width: 767px) {
  .socket-table {
    width: 100%;
  }

  .popular-table {
    width: 100%;
    padding-left: 0px;
  }

  .tableFixHead {
    height: 47vh;
    padding-left: 0;
    padding-right: 0;
  }

  .stock-text {
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;
  }

  .rt-resizable-header-content {
    font-size: 11px;
    font-weight: 500;
    text-align: center;
  }
}

/** Mobile Devices */
@media only screen and (max-width: 576px) {
  .stock-header-text {
    font-size: 0.8em;
  }

  .btn-max {
    right: -0.2em;
  }

  th.text-white {
    padding-left: 0.2em;
    padding-right: 0.2em;
  }

  .socket-table {
    width: 100%;
  }

  .popular-table {
    width: 100%;
    padding-left: 0px;
  }

  .tableFixHead {
    padding-left: 0;
    padding-right: 0;
  }

  .stock-text {
    font-size: 12px;
    text-align: center;
    margin-bottom: 0;
  }

  .input-border {
    outline: none !important;
    border: 1px solid gray;
    border-radius: 10px;
    font-size: 13px;
    height: 34px;
    margin-top: 15px;
  }

  #search-bar-0 {
    outline: none !important;
    border: 1px solid gray;
    border-radius: 10px;
    font-size: 13px;
    height: 34px;
    margin-top: 15px;
    background: #0d0d0d !important;
    text-align: center;
    color: white;
  }

  .mT15 {
    margin-top: 15px;
  }
  @media only screen and (max-width: 768px) {
    .mT15 {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .rt-resizable-header-content {
    font-size: 10px;
    font-weight: 500;
    text-align: center;
  }

  .pagination-bottom .-pagination {
    display: block;
  }

  .stockwits {
    position: absolute;
    border-radius: 0 !important;
    width: 12px !important;
    height: 12px !important;
    right: 32%;
  }
  .stockwits.stream-badge-size-sm {
    width: 10px !important;
    height: 10px !important;
  }

  .stream-quote-star {
    position: absolute;
    margin-left: 0.5em;
    right: calc(32% - 1px);
  }
  .stream-quote-star.stream-badge-size-sm {
    margin-left: 0.45em;
    font-size: 0.7rem;
  }
}

.data-separator {
  height: 1em;
  width: 1em;
}
.basic-container {
  width: 100%;
  flex-direction: row;
  display: flex;
  /* padding: 1em 0px; */
  min-height: 680px;
}
.basic-container:empty {
  display: none;
}
.basic-popular {
  /* width: 35%; */
}
.basic-data-separator {
  width: 1em;
  height: 1em;
}
.quote-card {
  padding: 0.5em;
  overflow: hidden;
}

.quote-card .card-padding {
  padding-left: 6px;
}

.quote-card .current-price {
  font-weight: 600;
  font-size: 20px;
  margin-left: 5px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
}

.quote-card table.quote-change-values {
  font-size: 13.5px;
  font-weight: 400;
  line-height: 14px;
  width: fit-content;
  margin-left: 6px;
}

.quote-card table.quote-change-values td:first-child {
  text-align: right;
}

.quote-star {
  color: #f6c548;
}

.table-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0);
  border-bottom: 1px solid #2c2e33;
  background: #000000;
}

.table-max {
  height: 100%;
  overflow: auto;
}

.discovery-normal {
  height: calc(65vh);
}

.discovery-max {
  /* padding-right: 1rem; */
  height: 100%;
}

.add-quote {
  position: absolute;
  bottom: 1em;
  left: 1em;
  z-index: 2;
}

.quotes-area {
  display: flex;
  flex-direction: row;
}

.quote-tools {
  display: flex;
  margin-top: 8px;
  margin-left: 0.5rem;
  margin-bottom: 43px;
  padding-left: 0.2em;
  padding-right: 0.2em;
  margin-right: 0.5rem;
  justify-content: space-around;
  min-width: 2em;
  min-height: 100px;
  /* Remove after implemented + */
}

.quote-tools .expand-quoute-icon {
  font-size: 1.6em;
  transform: rotate(0deg);
  transition: transform 0.1s linear;
}

.quote-tools .expand-quoute-icon.expanded {
  transform: rotate(180deg);
}

.quotes-swiper {
  flex: 1;
  float: left;
}

.quotes-area .quote-dropdown-wrapper {
  position: relative;
  /* margin-top: 20px; */
  flex-grow: 1;
  z-index: 1000;
}

.quote-dropdown-wrapper .quote-card .handler {
  display: none;
}
@media only screen and (max-width: 576px) {
  .quote-dropdown-wrapper.layout-unlocked.expanded .quote-card .handler {
    opacity: 1 !important;
  }
}
.quote-dropdown-wrapper.layout-unlocked.expanded .quote-card .handler {
  opacity: 0;
  color: #6d7291;
  transition: all 0.5s;
  cursor: grab;
  position: absolute;
  display: block;
  top: -12px;
  left: calc(50% - 8px);
  font-size: 24px;
}
.quote-dropdown-wrapper.layout-unlocked.expanded .quote-card:hover .handler {
  opacity: 1;
}

.quotes-area .quote-dropdown-wrapper .quote-dropdown-inner-wrapper {
  position: absolute;
  background: black;
  overflow-y: auto;
  max-height: 0;
  width: calc(100% - 8px);
  right: 8px;
  border-radius: 10px;
  box-shadow: 0 0 3px 2px #404040;
  transition: max-height 0.5s ease-in-out;
}

.quotes-area .quote-dropdown-wrapper.expanded .quote-dropdown-inner-wrapper {
  max-height: 100vh;
}

.quotes-area .quote-dropdown-wrapper .quote-dropdown-inner-wrapper .quote-card {
  display: inline-block;
  width: calc(100% / 6);
}

@media screen and (max-width: 1399px) {
  .quotes-area .quote-dropdown-wrapper .quote-dropdown-inner-wrapper .quote-card {
    width: calc(100% / 5);
  }
}

@media screen and (max-width: 1199px) {
  .quotes-area .quote-dropdown-wrapper .quote-dropdown-inner-wrapper .quote-card {
    width: calc(100% / 4);
  }
}

@media screen and (max-width: 991px) {
  .quotes-area .quote-dropdown-wrapper .quote-dropdown-inner-wrapper .quote-card {
    width: calc(100% / 3);
  }
}

@media screen and (max-width: 767px) {
  .quotes-area .quote-dropdown-wrapper .quote-dropdown-inner-wrapper .quote-card {
    width: calc(100% / 2);
  }
}

@media screen and (max-width: 575px) {
  .quotes-area .quote-dropdown-wrapper .quote-dropdown-inner-wrapper .quote-card {
    width: calc(100% / 1);
  }
}

.quotes-area .quote-dropdown-wrapper .quote-dropdown-inner-wrapper .bullets-section {
  height: 0;
}

.add-quote-icon {
  font-size: 1.6em;
}

.quote-sort-tools-wrapper {
  position: absolute;
  display: none;
  left: 0;
  top: 10px;
  overflow: hidden;
}

.quote-dropdown-wrapper.expanded .quote-sort-tools-wrapper {
  display: block;
}

.quote-sort-tools-wrapper .quote-sort-tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  border-radius: 10px;
  border: 2px solid #404040;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 2px 2px 2px 8px;
  font-size: 14px;
  transform: translateX(-78px);
  transition: transform 0.1s linear;
}

.quote-sort-tools-wrapper .quote-sort-tools .badge-toggle-indicator,
.quote-sort-tools-wrapper .quote-sort-tools .btn-toggle-link {
  opacity: 0;
}

.quote-sort-tools-wrapper.expanded .quote-sort-tools .badge-toggle-indicator,
.quote-sort-tools-wrapper.expanded .quote-sort-tools .btn-toggle-link {
  opacity: 1;
}

.quote-sort-tools-wrapper .quote-sort-tools .expand-tools-icon {
  font-size: 18px;
  transform: rotate(0deg);
  transition: transform 0.1s linear;
  user-select: none;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 4px;
}

.quote-sort-tools-wrapper.expanded .expand-tools-icon {
  transform: rotate(180deg);
  padding-left: 8px;
  padding-right: 12px;
}

.quote-sort-tools-wrapper.expanded .quote-sort-tools {
  transform: translateX(0);
}

.quote-edit-popup-error-msg {
  display: block;
  font-size: 14px;
}

.nopadding {
  padding: 0 !important;
}

.stream-body {
  padding: 0 1.5rem 1rem !important;
}

@media only screen and (max-width: 767px) {
  .stream-section-wrapper:not(.table-max) {
    height: 62vh;
  }
}

.stream-section-wrapper table td {
  padding: 0.7rem 0.1rem;
}

.pr-2 {
  cursor: pointer;
}

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1010;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 50%;
  left: 50%;
}

.fa {
  cursor: pointer;
}

.card-padding {
  padding-left: 12px;
  padding-right: 12px;
}

.container-padding {
  margin-bottom: 8px;
}

.horizontal-quote-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.vertical-quote-container {
  display: flex;
  flex-direction: column;
}

.quote-label-container {
  max-width: 140px;
  /* width: 140px; */
  margin-right: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.quote-dropdown-inner-wrapper .quote-label-container {
  max-width: 160px;
  /* width: 160px; */
}

.quote-status-label {
  font-size: 16px;
  width: 16.5px;
  color: #ffff;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.sector-select {
  background-color: transparent !important;
  outline: none !important;
  color: white !important;
  border: none !important;
}

.th-item-style {
  color: white;
  font-size: 18px;
  font-weight: 800;
  text-align: center;
}

.th-item-wrapper {
  display: flex;
  font-weight: 600;
  flex-direction: row;
  justify-content: center;
}

.filter-icon-wrapper {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
}

.th-action-item-style {
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
}

.react-contexify__item:hover {
  background-color: #0d0d0d !important;
}

.react-contexify__item:hover .react-contexify__item__content {
  background-color: #0d0d0d !important;
}

.context-menu-style {
  background-color: rgba(0, 0, 0, 0.9);
  padding: 6px 24px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 210px;
}
@media only screen and (max-width: 768px) {
  .context-menu-style {
    /* margin-top: -0px !important; */
  }
}

.context-menu-alert-style {
  background-color: rgba(0, 0, 0, 0.9);
  padding-top: 6px;
  padding-bottom: 6px;
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.context-menu-item-icon-style {
  width: 16px;
  color: #ffff;
}

.stock-percent {
  font-size: 70%;
  margin-left: 0.5em;
}

.industry_input {
  background: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0px 12px;
}

.industry_span {
  display: flex;
  align-items: center;
}

.filter-bar-wrapper {
  display: flex;
  height: 34px;
  padding: 4px;
  /* border-radius: 10px; */
  justify-content: center;
  /* border: 1px solid rgb(128, 128, 128);
  background-color: rgba(0, 0, 0, 0.9); */
}

.filter-bar-wrapper-hover:hover {
  /* border: 1px solid rgb(128, 128, 128, 0.5); */
}

.search-bar-wrapper {
  /* border: 1px solid #505050; */
  border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.9);
  position: relative;
}

.search-bar {
  width: 70px;
  height: 26px;
  color: white;
  font-size: 14px;
  /* border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.9); */
  background-color: rgba(0, 0, 0, 0.9);
}
.search-bar.variation-chat {
  width: 105px;
}

.search-icon-wrapper {
  border: 0px;
  padding-left: 6px;
  padding-right: 6px;
  cursor: default;
  background-color: rgba(0, 0, 0, 0.9);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.discovery-filter-container {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.4rem;
  padding-top: 1rem;
}

@media (max-width: 768px) {
  .discovery-header {
    order: 1;
  }
  .discovery-filter-left {
    order: 3;
    width: 100%;
  }
  .discovery-filter-right {
    order: 2;
  }
  .dashboard-maximized .discovery-filter-right {
    margin-right: 30px;
  }
  .discovery-filter-container {
    padding-right: 1.5rem;
  }
  .dashboard-maximized .discovery-filter-container {
    padding-top: 0;
    padding-right: 1rem;
  }
}

@media all and (max-width: 575px) {
  .item-wrap {
    flex-wrap: wrap;
  }
  /* .search-bar {
    width: 100%;
  } */
}

.no-wrap {
  white-space: nowrap;
}

.dropdown-item {
  color: #fff !important;
  font-size: 0.9rem !important;
}

.remove-cursor {
  cursor: pointer !important;
}

.alert-section {
  height: 100%;
  /* overflow: auto; */
}

.alert-container {
  flex: 1 1 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.alert-container .clear-history {
  font-size: 1rem;
  cursor: pointer;
}

.alert-container .clear-history:hover {
  text-decoration: underline;
}

.add-quote-empty {
  text-align: center;
}

@media only screen and (max-width: 576px) {
  .footer {
    font-size: 0.65rem !important;
  }
}

.symbol-blink {
  animation-duration: 2500ms;
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.symbol-blink.blink-high {
  animation-name: highcolorblink;
}
.symbol-blink.blink-low {
  animation-name: lowcolorblink;
}
@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes highcolorblink {
  from {
    color: rgb(0, 210, 91);
  }
  to {
    opacity: white;
  }
}
@keyframes lowcolorblink {
  from {
    color: rgb(252, 66, 74);
  }
  to {
    opacity: white;
  }
}

.stream-load-recent-section {
  color: white;
  font-size: 1.2rem;
  line-height: 1.35em;
}
.btn-load-recent {
  padding: 0.2em 1.5em;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}
.btn-load-recent:hover {
  background-color: #727272;
}

@media all and (max-width: 575px) {
  .swiper-container-horizontal > .swiper-pagination-bullets {
    padding-right: 20px;
    line-height: 0.8em;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 2px;
    width: 5px;
    height: 5px;
  }
}

.btn-toggle-link {
  font-size: 14px;
  cursor: pointer;
}

.badge-toggle-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f6c548;
  display: inline-block;
  visibility: hidden;
}
.badge-toggle-indicator.active {
  visibility: visible;
}

.text-single-line-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.text-double-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.3rem;
}

.text-three-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.3rem;
}

.card.news-container-shrink {
  max-width: 35%;
}
@media only screen and (max-width: 812px) {
  .card.news-container-shrink {
    max-width: unset;
  }
}

.news-container,
.chat-container {
  flex: 1 1 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.news-section {
  height: 100%;
  /* overflow: auto; */
}

.news-item {
  margin-top: 2px;
  margin-bottom: 2px;
}

.news-item span.news-title {
  font-size: 16px;
  color: #bbbbbb;
  flex-grow: 1;
}

.news-item:hover {
  cursor: pointer;
}

.news-item:hover span.news-title {
  text-decoration: underline;
  color: #ebebeb;
}

.news-item .news-dt {
  white-space: nowrap;
  font-size: 14px;
  color: rgb(191, 191, 191);
  margin-left: 10px;
  /* flex-basis: 85px; */
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
}

.news-item span.news-symbols {
  white-space: nowrap;
  font-size: 16px;
  color: #bbbbbb;
  margin-left: 10px;
  display: inline;
  font-weight: 500;
}

.chat-container .message-text .chat-msg-username {
  color: #0090e7;
  background: #191c24;
  margin-left: 2px;
  margin-right: 2px;
}
.chat-container .message-text .chat-msg-symbol {
  color: #f6c548;
  background: #191c24;
  margin-left: 2px;
  margin-right: 2px;
}

/* .news-item:hover span.news-symbols:not(:empty) {
  display: inline;
  font-weight: bold;
} */

.symbol-context-menu-divider {
  border: 1px solid #dddddd;
  border-bottom: none;
}

.news-arrow {
  color: #9f9f9f;
  font-size: 16px;
  font-weight: bold;
  transform: scaleY(1.5);
  cursor: pointer;
  transition: all 0.2s;
  padding: 0 5px;
}

.news-arrow:hover {
  color: white;
}

/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.news-recent-item,
.chat-channel-item {
  border: 1px solid #9f9f9f;
  border-radius: 13px;
  padding: 0 5px 0 8px;
  font-size: 14px;
  color: #9f9f9f;
  transition: all 0.2s;
  white-space: nowrap;
  cursor: pointer;
}
.chat-channel-item {
  padding: 0 10px;
}
.news-recent-item.active,
.chat-channel-item.active {
  background-color: #505050;
}
.news-recent-item.active,
.news-recent-item:hover,
.chat-channel-item.active,
.chat-channel-item:hover {
  border-color: white;
  color: white;
}
.news-recent-item .btn-news-recent-delete {
  margin-left: 4px;
}
.news-recent-item .btn-news-recent-delete:hover {
  font-weight: bolder;
}

.modal-news-detail .modal-body {
  color: #c4c4c4;
}

.modal-news-detail .modal-body > hr {
  background-color: #c4c4c4;
}

.modal-news-detail .news-source a {
  color: #c4c4c4;
  text-decoration: underline;
}

.modal-news-detail .news-source a:hover {
  color: white;
}

.news-container .horizontal-menu .menu-item-wrapper {
  outline: none;
}

.btn-news-loadmore {
  user-select: none;
  font-size: 16px;
  cursor: pointer;
}

.btn-news-loadmore:hover {
  text-decoration: underline;
}

td.stream-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .stream-badge-fav {
  position: absolute;
  left: -20px;
}
@media only screen and (max-width: 576px) {
  .stream-badge-fav {
    left: -12px;
  }
} */

.stream-badge-wrapper {
  position: relative;
  margin-top: -11px;
  margin-bottom: -11px;
  height: calc(100% + 22px);
}
.stream-badge-container {
  width: 16px;
  margin-top: 3px;
  margin-left: 0.5em;
  position: absolute;
}
.stream-badge-container img {
  border-radius: 0 !important;
}
.stream-badge-container .stream-badge-fav {
  margin: 1px 0;
  font-size: 14px;
}
.stream-badge-container .stream-badge-st {
  margin: 1px 0;
  width: 13px;
  height: 13px;
}
.stream-badge-container .stream-badge-news {
  margin: 1px 0;
  width: 13px;
  height: 13px;
}

.stream-badge-container.stream-badge-1 {
  margin-top: 10px;
}

.stream-badge-container.stream-badge-3 {
  width: 30px;
  margin-top: 2px;
}
.stream-badge-container.stream-badge-3 .stream-badge-fav {
  order: 1;
  font-size: 14px;
}
.stream-badge-container.stream-badge-3 .stream-badge-st {
  order: 2;
  width: 13px;
  height: 13px;
}
.stream-badge-container.stream-badge-3 .stream-badge-news {
  order: 1;
  margin-left: 2px;
  width: 13px;
  height: 13px;
}

.stream-badge-luld {
  position: absolute;
  left: calc(100% + 4px);
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.stream-badge-luld .stream-badge-halt {
  display: block;
  font-size: 18px;
  color: rgb(246, 197, 72);
}
@media only screen and (max-width: 576px) {
  .stream-badge-luld {
    left: 100%;
    top: 0;
  }
}
@media only screen and (min-width: 577px) {
  .stream-badge-luld.stream-badge-luld-spacing-1,
  .stream-badge-luld.stream-badge-luld-spacing-2 {
    left: calc(100% + 24px);
  }
  .stream-badge-luld.stream-badge-luld-spacing-3 {
    left: calc(100% + 40px);
  }
}

@media only screen and (max-width: 576px) {
  .stream-badge-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: unset;
    margin-bottom: unset;
    height: unset;
    right: calc(30% + 20px);
  }

  .stream-badge-container {
    width: 16px;
    margin-top: 5px;
    margin-left: 0.25em;
    position: absolute;
  }
  .stream-badge-container img {
    border-radius: 0 !important;
  }
  .stream-badge-container .stream-badge-fav {
    margin: 1px 0;
    font-size: 12px;
  }
  .stream-badge-container .stream-badge-st {
    margin: 1px 0;
    width: 12px;
    height: 12px;
  }
  .stream-badge-container .stream-badge-news {
    margin: 1px 0;
    width: 12px;
    height: 12px;
  }

  .stream-badge-container.stream-badge-1 {
    margin-top: 12px;
  }

  .stream-badge-container.stream-badge-3 {
    width: 30px;
    margin-top: 5px;
  }
  .stream-badge-container.stream-badge-3 .stream-badge-fav {
    order: 1;
    font-size: 12px;
  }
  .stream-badge-container.stream-badge-3 .stream-badge-st {
    order: 2;
    width: 12px;
    height: 12px;
  }
  .stream-badge-container.stream-badge-3 .stream-badge-news {
    order: 1;
    margin-left: 1px;
    width: 12px;
    height: 12px;
  }
}

.search-bar-wrapper .search-bar:focus + .search-icon-wrapper .fa-search {
  animation-duration: 200ms;
  animation-name: searchzoom;
  animation-iteration-count: 2;
  animation-direction: alternate;
}
@keyframes searchzoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

input.input-number-hide-spinner::-webkit-outer-spin-button,
input.input-number-hide-spinner::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input.input-number-hide-spinner[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.discovery-tbl-header {
  user-select: none;
}

.input-group-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.input-group-checkbox .chk-box {
  width: 15px;
  height: 15px;
  background-color: gray;
}
.input-group-checkbox .chk-box.checked {
  background: #f6c548;
}
.input-group-checkbox .chk-label {
  margin-left: 0.5rem;
  font-size: 12px;
}

.text-double-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.3rem;
}

.alert-item-detail {
  display: none;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0 8px 0 5px;
}
.alert-item:hover .alert-item-detail {
  display: block;
}

.alert-detail-item-clear {
  font-size: 1rem;
  cursor: pointer;
}

.btn.btn-quote-pin {
  padding: 0;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.1s ease-in;
}

.btn.btn-quote-pin:hover {
  transform: scale(1.4);
}

@media (hover: none) {
  .btn.btn-quote-pin:hover {
    transform: scale(1);
  }
}

.btn.btn-quote-pin.rotate {
  transform-origin: 6.5px 6.5px;
  animation-duration: 2000ms;
  animation-name: quote_pin_rotate;
  animation-iteration-count: 1;
  /* animation-direction: alternate; */
}

@keyframes quote_pin_rotate {
  0% {
    transform: rotate(0deg) scale(1.4);
  }
  75% {
    transform: rotate(360deg) scale(1.4);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

.discovery-timer {
  cursor: pointer;
  position: relative;
}

.discovery-timer .discovery-manual-refresher {
  display: none;
  font-size: 20px;
  padding: 0;
  width: 26px;
  height: 26px;
  margin-top: -1px;
  position: absolute;
  top: 0;
}

.discovery-timer:hover .countdown-circle-timer {
  opacity: 0;
}
.discovery-timer.refreshing .countdown-circle-timer {
  opacity: 0;
  animation-duration: 2000ms;
  animation-name: discovery-timer-fadeIn;
  animation-iteration-count: 1;
}

.discovery-timer:hover .discovery-manual-refresher {
  display: block;
}
.discovery-timer.refreshing .discovery-manual-refresher {
  display: block;
  animation-duration: 2000ms;
  animation-name: discovery-manul-refresh;
  animation-iteration-count: 1;
  /* animation-timing-function: linear; */
}
.discovery-timer .discovery-manual-refresher svg {
  fill: white;
}
.discovery-timer.manual-disabled .discovery-manual-refresher svg {
  fill: rgb(155, 155, 156);
}

.dropdown .timeframe-dropdown-menu.dropdown-menu .dropdown-item {
  padding: 0.4rem 1rem;
}

@keyframes discovery-timer-fadeIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes discovery-manul-refresh {
  0% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(360deg);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0;
  }
}

.react-grid-layout {
  position: relative;
}
.react-grid-layout .react-grid-item {
  position: relative;
}

.react-grid-layout.layout-unlocked .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-grid-layout.layout-unlocked .react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}

.react-grid-layout.layout-unlocked .react-grid-item:hover > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 10px;
  height: 10px;
  border-right: 2px solid #6d7291;
  border-bottom: 2px solid #6d7291;
}

.stream-widget-btn {
  width: 16px;
}

/* .react-grid-layout .react-grid-item .layout-dnd-handler {
  display: none;
}
@media only screen and (max-width: 576px) {
  .react-grid-layout.layout-unlocked .react-grid-item .layout-dnd-handler {
    opacity: 1 !important;
  }
} */

.layout-widget-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 120px;
  min-height: 24px;
  border: 1px solid #6d7291;
  border-radius: 4px;
  background-color: #191c24;
  padding: 1px 8px;
}

.layout-widget-toolbar .layout-dnd-handler {
  color: white;
  cursor: grab;
  font-size: 24px;
  line-height: 5px;
}

.layout-widget-toolbar > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.layout-widget-toolbar > span > * {
  cursor: pointer;
}

.layout-widget-toolbar-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-grid-layout.layout-locked .react-grid-item .layout-widget-toolbar-outer {
  display: none;
  opacity: 0;
}

.react-grid-layout.layout-unlocked .react-grid-item .layout-widget-toolbar-outer,
.dashboard-maximized .layout-widget-toolbar-outer {
  z-index: 9;
  top: 5px;
  left: calc(50%);
  transform: translateX(-50%);
  position: absolute;
  transition: all 0.5s;
  opacity: 0;
}

.react-grid-layout.layout-unlocked .react-grid-item:hover .layout-widget-toolbar-outer {
  opacity: 1;
}

.dashboard-maximized {
  position: relative;
}

.dashboard-maximized .layout-widget-toolbar {
  border-color: #4d5066 !important;
}

.dashboard-maximized:hover .layout-widget-toolbar-outer {
  opacity: 1;
}

.layout-widget-toolbar-hider {
  overflow: hidden;
  max-height: 0px;
}

.collapse-toolbar {
  max-height: 0;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.expand-toolbar {
  max-height: 30px;
  opacity: 1;
  transition: all 0.2s ease-out;
}

.expand-button {
  width: fit-content;
  font-size: 20px;
  line-height: 10px;
  transform: scaleX(1.5);

  cursor: pointer;
}

.dashboard-flow {
  min-height: 240px;
}
