.text-pink {
  color: pink;
}

.value-item {
  padding-left: 15em;
  padding-right: 15em;
  padding-top: 3em;
}

.value-item-disabled {
  padding-left: 15em;
  padding-right: 15em;
  padding-top: 1em;
}

.pricing-container {
  padding-left: 2em;
}

.item-content {
  background: #2d2d2d;
  padding-right: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.symbol {
  padding-left: 2em;
}

.text-symbol {
  color: #828282;
}

.text-alert {
  color: #fac138;
}

.progress-section {
  padding-left: 3em;
  padding-right: 3em;
}

.price-section {
  padding-left: 10em;
  padding-right: 2em;
  margin-top: 1em;
}

.progress-value {
  padding-top: 1px;
  padding-bottom: 1px;
  width: 50px;
}

.progress-input {
  padding-top: 3px;
  padding-bottom: 3px;
  width: 50px;
}

.pricing-separator {
  margin-top: 2em;
}

.company-name {
  width: 50px;
  text-align: center;
}

.industry-row {
  margin-top: 10px;
  padding-left: 2em;
  cursor: pointer;
  width: 20%;
}
@media only screen and (max-width: 1400px) {
  .industry-row {
    width: 33%;
  }
}
@media only screen and (max-width: 1190px) {
  .industry-row {
    width: 50%;
  }
}

@media only screen and (max-width: 1024px) {
  .industry-row {
    width: 50%;
  }
}
@media only screen and (max-width: 812px) {
  .industry-row {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .industry-row {
    width: 100%;
  }
}

.industry-txt {
  margin-left: 0.5rem;
  font-size: 12px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.company-name-margin {
  width: 100px;
  text-align: left;
  margin-top: 5px;
}

.industry-checked {
  min-width: 15px;
  width: 15px;
  height: 15px;
  background: #f6c548;
}

.industry-container {
  margin-top: 0;
  background: #2d2d2d;
}

.industry-unchecked {
  min-width: 15px;
  width: 15px;
  height: 15px;
  background: gray;
}

.cursor-pointer {
  cursor: pointer;
}

.alert-edit {
  border: 1px dashed gray;
}

.noUi-target .noUi-handle {
  background: transparent;
  border: none;
  width: 18px;
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  right: -10px;
}

.noUi-target.noUi-horizontal .noUi-handle {
  top: -7px;
}

.noUi-connects {
  background: yellow;
}

.noUi-target .noUi-base .noUi-connect {
  background: white;
}

.noUi-tooltip {
  background: transparent;
  color: white;
  padding: 0;
  border: none;
  font-size: 12px;
}

.slider-range .noUi-connects {
  background: white;
}

.slider-range .noUi-connect {
  background: yellow !important;
}

.edit-alert-input {
  background: transparent;
  color: white;
  border: solid 1px white;
}

.edit-alert-input:focus {
  border: dotted 1px white;
}

.edit-rate-input {
  background: transparent;
  color: white;
  border: solid 1px white;
}

.edit-rate-input:focus {
  border: dotted 1px white;
}

.settings-content {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-top: 60px;
}

@media only screen and (max-width: 1024px) {
  .value-item {
    padding-left: 0em;
    padding-right: 0em;
  }
}

/** Small iPads */
@media only screen and (max-width: 812px) {
  .value-item {
    padding-left: 0em;
    padding-right: 0em;
    padding-top: 3em;
  }

  .value-item-disabled {
    padding-left: 3em;
    padding-right: 3em;
  }

  .item-content {
    background: #2d2d2d;
    padding-right: 10px;
  }

  .progress-section {
    padding-left: 3em;
    padding-right: 3em;
  }

  .symbol {
    padding-left: 2em;
  }

  .price-section {
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 2em;
  }

  .pricing-container {
    padding-left: 10px;
  }
}

/** Large Mobiles */
@media only screen and (max-width: 767px) {
  .value-item {
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 3em;
  }

  .value-item-disabled {
    padding-left: 3em;
    padding-right: 3em;
  }

  .item-content {
    background: #2d2d2d;
    padding-right: 10px;
  }

  .progress-section {
    padding-left: 3em;
    padding-right: 3em;
  }

  .symbol {
    padding-left: 2em;
  }

  .price-section {
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 2em;
  }

  .pricing-container {
    padding-left: 10px;
  }
}

/** Mobile Devices */
@media only screen and (max-width: 415px) {
  .value-item {
    padding-left: 0em;
    padding-right: 0em;
    padding-top: 1em;
  }

  .value-item-disabled {
    padding-left: 0em;
    padding-right: 0em;
  }

  .item-content {
    background: #2d2d2d;
    padding-right: 10px;
  }

  .progress-section {
    padding-left: 0.5em;
    padding-right: 1em;
  }

  .symbol {
    padding-left: 0.5em;
  }

  .price-section {
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 2em;
  }

  .pricing-container {
    padding-left: 10px;
  }
}

@media only screen and (max-height: 415px) {
}

.transparent-txt {
  color: transparent !important;
}

.action-area {
  width: 3em;
  align-items: flex-end;
  flex-direction: column;
}

.bb-title {
  border-bottom: 2px solid gray;
  font-size: 1.2em;
}

.settings-label {
  margin-bottom: 0px !important;
}

.delete-alert {
  z-index: 2;
}

.btn-toggle-state {
  padding: 0px 8px;
  background-color: gray;
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;
  user-select: none;
}
/* .btn-toggle-state:hover {
  background-color: #bbb;
} */

.btn-toggle-state.active {
  background-color: #f6c548;
}
/* .btn-toggle-state.active:hover {
  background-color: #f6ad48;
} */

.discovery-column-sortable .btn-toggle-state {
  width: 86px;
}

.setting-save-appear {
  opacity: 1 !important;
}
.setting-save-appear.setting-save-appear-active {
  opacity: 0 !important;
  transform: translateY(-20px);
  transition: all 1000ms ease-in;
}

.voice-setting-save-appear {
  opacity: 0 !important;
  transform: translateX(150px);
}
.voice-setting-save-appear.voice-setting-save-appear-active {
  opacity: 1 !important;
  transform: translateX(0);
  transition: all 200ms ease-in;
}

/* @media only screen and (max-width: 576px) {
  .voice-setting-save-appear {
    opacity: 0 !important;
    transform: translateY(-30px);
  }
  .voice-setting-save-appear.voice-setting-save-appear-active {
    opacity: 1 !important;
    transform: translateY(0);
    transition: all 200ms ease-in;
  }
} */

.saving-animator {
  color: white;
  font-size: 16px;
  margin-left: 5px;
  opacity: 0;
}

.csv-dropzone {
  border: 2px dashed gray;
  user-select: none;
  cursor: pointer;
  color: gray;
  font-size: 13px;
  height: 50px;
}
.csv-dropzone:hover {
  color: white;
  border-color: white;
}
.section-custom-view .btn-toggle-state {
  width: 80px;
}

.user-symbols-status {
  font-size: 13px;
}

.btn-collapse-toggler,
.btn-collapse-toggler > label {
  cursor: pointer;
}
.btn-collapse-toggler > i.mdi {
  font-size: 24px;
}

.custom-radio-wrapper-label {
  font-size: 12px;
  margin-right: 4px;
}

.custom-radio-wrapper .custom-radio-label {
  margin-left: 0.5rem;
  font-size: 12px;
  text-transform: uppercase;
  white-space: nowrap;
}

.custom-radio-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 2em;
  width: 20%;
  cursor: pointer;
}
.custom-radio-wrapper .custom-radio-badge {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: gray;
}
.custom-radio-wrapper.active .custom-radio-badge {
  background: #f6c548;
}
@media only screen and (max-width: 576px) {
  .custom-radio-wrapper {
    width: unset;
    padding-left: unset;
    margin-right: 1.5rem;
  }
}

.filtered-alerts .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}
.filtered-alerts .section-header > hr {
  flex-grow: 1;
  margin-top: 0;
  margin-bottom: 0;
  border-top: 1px solid #8c8c8c;
}
.filtered-alerts .section-header > span {
  margin: 0 10px;
  color: #e6e6e6;
}
.filtered-alerts span.filtered-alert-item-label {
  white-space: nowrap;
  min-width: 120px;
}
.filtered-alerts .min-max-label {
  font-size: 0.75rem;
  color: #cccccc;
}
.filtered-alerts .form-check-inline {
  padding-left: 0;
}
.filtered-alerts .form-check-label {
  color: white;
  padding-left: 0;
}

.tab-header-wrapper {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  background-color: black;
  z-index: 10;
}

.tab-header {
  border-bottom: 2px solid #333333;
  font-size: 1rem;
}

.tab-header > .tab-header-item {
  padding: 5px 20px;
  cursor: pointer;
}

.tab-header > .tab-header-item.active {
  border-bottom: 2px solid #cccccc;
}

.tab-header > .tab-header-item > * {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tab-header > .tab-header-item {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    font-size: 1.2rem;
  }
  .tab-header > .tab-header-item > label {
    display: none;
  }
  .settings-content {
    padding-top: 50px;
  }
  .settings-content {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media only screen and (max-width: 576px) {
  .tab-header-wrapper {
    top: 50px;
  }
}

.alert-code-button {
  color: white;
  font-size: 1rem;
  cursor: pointer;
  margin-left: 10px;
}
.alert-code-button:hover {
  color: white;
}

.btn-expander {
  padding: 2px 5px;
  background-color: transparent;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.btn-expander:hover {
  background-color: #2a3038;
}

.sub-tab-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-tab-header > div {
  flex-grow: 1;
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sub-tab-header > div.active {
  background-color: #15171e;
}

.sub-tab-content {
  background-color: #15171e;
}

.sub-tab-header > div label {
  margin-bottom: 0;
}

.setting-panel-label-1 {
  font-size: 20px;
}

.setting-panel-label-2 {
  font-size: 18px;
}
