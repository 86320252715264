.menu-container {
  .bar-txt {
    margin-left: 8px;
  }
  .react-horizontal-scrolling-menu--arrow-left,
  .react-horizontal-scrolling-menu--arrow-right {
    align-items: center;
  }
  // .react-horizontal-scrolling-menu--inner-wrapper, .react-horizontal-scrolling-menu--scroll-container {
  //   overflow-y: unset;
  // }
}

.widget-btn {
  &.hideWidget {
    color: gray;

    .bar-icon i {
      color: gray !important;
    }
  }

  &.showWidget {
    color: white;
  }

  &.widget-btn-enabled {
    cursor: pointer;
  }
  &.widget-btn-disabled {
    cursor: initial !important;
  }
  &.widget-btn-gap-filler {
    span {
      color: #191c24;
    }
  }

  .bar-txt {
    display: none;
  }
  @media (min-width: 576px) {
    .bar-txt {
      display: block;
    }
  }
}

.btn-menu-more {
  &::after {
    display: none;
  }
}

.btn-menu-more-content {
  .dropdown-item {
    padding: 0.25rem 0.25rem !important;
    .widget-btn {
      margin-top: 8px !important;
      margin-bottom: 8px !important;
      // .bar-icon {
      //   width: 24px;
      //   height: 24px;
      // }
      .bar-txt {
        display: block;
      }
    }
  }
  @media (min-width: 576px) {
    & {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }
  }
  .lock-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.8);
    p {
      text-align: center;
      margin-bottom: 0;
      padding: 0 32px;
    }
  }
}

.btn-menu-pin {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: white;
    i {
      color: black;
    }
  }

  i {
    transition: color 0.3s ease;
    color: white;
  }
}

.chart-menu-icon-color {
  color: #00f8ff;
}
