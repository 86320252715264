.multigrid {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    cursor: pointer;
    border-radius: 4px;
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      background-color: rgba(102, 102, 102, 0.5);
    }
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  .sparklineLoading {
    animation: pulse 0.2s infinite;
  }

  @keyframes pulse {
    0% {
      opacity: 0.05;
    }
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.05;
    }
  }
}

.sparklineContent {
  > svg {
    width: 100%;
  }
}
.sparklineSpot {
  circle {
    opacity: 0;
    animation: sparklineSpotPulse 1.5s 0.2s forwards ease-out;
  }
}
.sparklineSpotHide {
  circle {
    opacity: 0;
  }
}

.custom-column-mark {
  position: absolute;
  top: 10px;
  left: -4px;
}

@keyframes sparklineSpotPulse {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.filterfader {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 4px;
  height: 100%;

  transform: scaleY(0.95);
  transform-origin: bottom;

  background-color: rgb(246, 197, 72);

  animation: filterfadercountdown forwards;
  animation-duration: var(--filterFadeoutTime);
  animation-delay: var(--filterFadeoutDelay);
}

@keyframes filterfadercountdown {
  0% {
    transform: scaleY(0.95);
  }
  100% {
    transform: scaleY(0);
  }
}
