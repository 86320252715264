.statsbar {
  flex: 1;
}

.statsbar.lows {
  padding: 12px 0px 0px 0px;
}

.statsbar.highs {
  padding: 12px 0px 0px 0px;
}

.carreContainer {
  width: 100%;
  text-align: center;
  height: 4px !important;
  margin-bottom: 2px;
}

.meters-body {
  display: flex;
}

.meters-area {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.meters-type {
  width: 3em;
  text-align: center;
  transform: scale(1, 0.75);
  font-weight: bold;
}

.meters-tile {
  flex: 1;
  margin-left: 2px;
  margin-right: 2px;
  height: 1em;
  border-radius: 3px;
}

.low {
  background-color: red;
}

.high {
  background-color: green;
}

.empty {
  background-color: rgba(0, 0, 0, 0.1);
}

.meter-label {
  margin-left: 12px;
}
