.root {
  // display: none;
  position: absolute;
  margin-right: 10px;
  right: 0;

  padding: 10px 16px;
  border-radius: 7px;
  border: solid 1px #ffffff33;
  background-color: black;

  .items {
    display: flex;
    align-items: center;

    .masterVolumeSlider {
      width: 90px;

      -webkit-appearance: none;
      appearance: none;

      height: 5px;
      border-radius: 4px;
      background-color: rgb(187 187 187);

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 14px;
        height: 14px;
        border-radius: 10px;
        background-color: #f6c548;
        overflow: visible;
        cursor: pointer;
      }
    }
  }
}
