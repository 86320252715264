.container {
  display: flex;
  height: 200px;
  margin-left: 0;
  flex-direction: column;
  max-width: 100% !important;
}
@media (min-width: 992px) {
  .container {
    max-width: 100% !important;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 100% !important;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 100% !important;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 130% !important;
  }
}

.ReactVirtualized__Table__headerRow {
  justify-content: space-between;
}
.ReactVirtualized__Table__row {
  justify-content: space-between;
}

.table-row {
  display: flex;
  overflow-x: auto;
  flex-direction: row;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.ReactVirtualized__Table__Grid {
  outline: none;
}

.ReactVirtualized__Table__headerRow {
  border: 0;
}

.ReactVirtualized__Table__headerColumn {
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  outline: none;
}

.table-style {
  width: 1320px;
  font-size: 14px;
}
@media only screen and (max-width: 768px) {
  .table-style {
    width: 600px;
  }
}

.ReactVirtualized__Table__sortableHeaderIcon {
  align-self: center;
  height: 24px !important;
  width: 24px !important;
  fill: #f6c548 !important;
}

.evenRow {
  // cursor: pointer;
  background-color: #191c24;
}
.tableTextColor {
  color: "#9B9B9C";
}
.oddRow {
  // cursor: pointer;
  background-color: black;
}

.action-column {
  display: flex;
  flex-direction: row;
}
.action-button-margin {
  margin-right: 8px;
  cursor: pointer;
}

.last-cell-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .last-dist-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .last-price {
    font-weight: 600;
    margin-right: 2px;
  }
  .last-dist {
    // position: absolute;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    .last-dist-wrapper {
      align-items: flex-start;
    }
  }
}

.discovery-filters {
  .form-check-inline {
    padding-left: 0;
  }
  .form-check-label {
    color: white;
    padding-left: 0;
  }
  .min-max-label {
    font-size: 0.75rem;
    color: #cccccc;
  }
}

.column-priority-1 {
  order: 1;
}
.column-priority-2 {
  order: 2;
}
.column-priority-3 {
  order: 3;
}
.column-priority-4 {
  order: 4;
}
.column-priority-5 {
  order: 5;
}
.column-priority-6 {
  order: 6;
}
.column-priority-7 {
  order: 7;
}
.column-priority-8 {
  order: 8;
}
.column-priority-9 {
  order: 9;
}
.column-priority-10 {
  order: 10;
}
.column-priority-11 {
  order: 11;
}
.column-priority-12 {
  order: 12;
}
.column-priority-13 {
  order: 13;
}
.column-priority-14 {
  order: 14;
}
.column-priority-15 {
  order: 15;
}
.column-priority-16 {
  order: 16;
}

#discovery-table {
  @media (max-width: 360px) {
    .column-priority-5,
    .column-priority-6,
    .column-priority-7,
    .column-priority-8,
    .column-priority-9,
    .column-priority-10,
    .column-priority-11,
    .column-priority-12,
    .column-priority-13,
    .column-priority-14,
    .column-priority-15,
    .column-priority-16 {
      display: none;
    }
  }
  @media (max-width: 450px) {
    .column-priority-6,
    .column-priority-7,
    .column-priority-8,
    .column-priority-9,
    .column-priority-10,
    .column-priority-11,
    .column-priority-12,
    .column-priority-13,
    .column-priority-14,
    .column-priority-15,
    .column-priority-16 {
      display: none;
    }
  }
  @media (max-width: 576px) {
    .column-priority-7,
    .column-priority-8,
    .column-priority-9,
    .column-priority-10,
    .column-priority-11,
    .column-priority-12,
    .column-priority-13,
    .column-priority-14,
    .column-priority-15,
    .column-priority-16 {
      display: none;
    }
  }
  @media (max-width: 768px) {
    .column-priority-8,
    .column-priority-9,
    .column-priority-10,
    .column-priority-11,
    .column-priority-12,
    .column-priority-13,
    .column-priority-14,
    .column-priority-15,
    .column-priority-16 {
      display: none;
    }
  }
}

.column-volume,
.column-moneyflow,
.column-tradecount {
  & > span:hover {
    // color: #f6c548 !important;
    border-bottom: 2px solid #f6c548;
    padding-bottom: 0;
    display: block;
    width: fit-content;
  }
}

.squeeze-header {
  cursor: pointer;
  max-width: "100%";
  overflow: hidden;

  .squeeze-sort {
    word-break: keep-all;
    white-space: nowrap;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 10px;
    font-weight: 500;
    background: rgb(255, 255, 255);
    height: 11px;
  }
  .squeeze-sort-now {
    color: black;
    background-color: #f6c548;
  }
  .squeeze-sort-pre,
  .squeeze-sort-post {
    color: black;
    background-color: white;
  }

  .squeeze-label {
    overflow: "hidden";

    &:after {
      content: "Squeeze";
    }
    @media only screen and (max-width: 992px) {
      &:after {
        content: "Sqz";
      }
    }
  }

  &.has-sort {
    display: flex;
    align-items: center;
    .squeeze-label {
      margin-right: 4px;
      white-space: nowrap;
      word-break: keep-all;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.last-header {
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;

  .last-label {
    overflow: "hidden";

    &:after {
      content: "Last";
    }
  }

  .last-sort {
    background-color: white;
    color: black;
    word-break: keep-all;
    white-space: nowrap;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    background: white;
    margin-left: 4px;
    height: 14px;
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid black;
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #f00;
}

.triangle,
.triangle:before,
.triangle:after {
  width: 4em;
  height: 4em;
}
.triangle {
  overflow: hidden;
  position: relative;
  // margin: 7em auto 0;
  margin: 0;
  text-align: left;
  border-radius: 20%;
  transform: translateY(50%) rotate(30deg) skewY(30deg) scaleX(0.866);
  // cursor: pointer;
  pointer-events: none;
}
.triangle:before,
.triangle:after {
  position: absolute;
  background: orange;
  pointer-events: auto;
  content: "";
}
.triangle:before {
  border-radius: 20% 20% 20% 53%;
  transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(30deg) scaleY(0.866) translateX(-24%);
}
.triangle:after {
  border-radius: 20% 20% 53% 20%;
  transform: scaleX(1.155) skewY(-30deg) rotate(-30deg) translateY(-42.3%) skewX(-30deg) scaleY(0.866) translateX(24%);
}

.trend-wrapper {
  font-size: 4px;
  transform: rotateX(180deg) translateY(-4px);
  .triangle:before,
  .triangle:after {
    background: #fc424a;
  }
  &.trending {
    transform: rotateX(0) translateY(0) !important;
    -webkit-transform: unset;
    .triangle:before,
    .triangle:after {
      background: #00d25b;
    }
  }
}

.discovery-halt-luld {
  .cell-halt {
    display: block;
    font-size: 18px;
    color: rgb(246, 197, 72);
  }
}
.ReactVirtualized__Grid:focus {
  outline: none;
}
