.admin-tbl-header {
  user-select: none;

  > span {
    white-space: break-spaces;
  }
}

.action-button {
  background: unset;
  border: none;
  padding: 0;
  margin: 0;

  .action-toggle-mod {
    width: 24px;
    filter: grayscale(1);
    opacity: 0.5;
    &.active {
      filter: unset;
      opacity: 1;
    }
  }

  .action-toggle-chat {
    font-size: 24px;
    margin-right: 4px;
    color: #f6c548;
    &.disabled {
      color: rgb(252, 66, 74);
    }
  }
}
