.spin {
  animation: spin 0.4s ease-out forwards;
}

@keyframes spin {
  from {
    opacity: 0;
    transform: scale(2.4) rotate(0deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(180deg);
  }
}

.ScrollBars {
  height: 45vh !important;
  max-height: fit-content;

  & > div:first-child {
    margin-bottom: 0px !important;
    position: unset !important;
    height: 100%;
    max-height: fit-content;

    overflow-x: hidden !important;
  }
}
