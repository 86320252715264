.active-plan {
  border-color: darkgreen;
  border-width: 0.1rem;
}

.selected-plan {
  border-color: gray;
}

#paymentForm {
  border: 1px solid gray;
}

.textActive {
  font-weight: 600;
}

.form-control:disabled {
  background-color: #191c24 !important;
}

.change_card {
  right: 0.5em;
  top: 0.5em;
  position: absolute;
  font-size: 0.8em;
}

.my_card {
  margin-left: auto !important;
  margin-right: auto !important;
}

.card-container {
  border: 1px solid gray;
}

.couponCode {
  font-size: 0.8em;
  margin-bottom: 1.6em;
}

.cancelBt {
  margin-top: 0.5em;
}

.selectBt {
  background-color: transparent;
}

.cardBt {
  width: 80%;
}

.bottomDiv {
  width: 100%;
  position: absolute;
  bottom: 1rem;
  left: 0;
}

.plan-card {
  border: 1px solid #00d25b;
}

.coupon-apply {
  background-color: transparent;
  border: 1px solid grey;
  height: 98%;
  width: 110px;
  color: white !important;
}

.activeBtn {
  border: 1px solid #00d25b;
  color: #00d25b !important;
}

.activeBtn:hover {
  border: 1px solid #00d25b;
  color: #00d25b !important;
  background-color: transparent !important;
}

.activeTyping {
  color: white !important;
}

.footer-container {
  width: 100%;
}

#currentCard {
  display: flex;
}

.successBt {
  width: 60%;
}

.couponWrapper {
  margin-bottom: 0px;
}

.couponContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
}

.disabled {
  background-color: transparent !important;
  border-color: #6b6b6b !important;
  color: #6b6b6b !important;
  opacity: 1;
}

.close {
  color: white !important;
}

.btn-cancel-subscription {
  font-size: 0.85rem;
  cursor: pointer;
  text-decoration: underline;
  color: #b5b5b7;
}

.custom-radio {
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  background: black;
  border-radius: 50%;
  border: 2px solid white;
  position: relative;
  cursor: pointer;

  &.active:after {
    width: 22px;
    height: 22px;
    content: " ";
    background: rgb(2, 210, 91);
    position: absolute;
    border-radius: 50%;
    left: 2px;
    top: 2px;
  }
}

.account-plan-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;

  @media (max-width: 991px) {
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    .plan-detail {
      margin-left: 80px;
    }
  }

  @media (max-width: 575px) {
    .plan-title {
      font-size: 1.2rem !important;
    }
    .plan-detail {
      font-size: 1.2rem !important;
    }
  }

  .plan-title {
    font-size: 1.5rem;
  }
  .plan-detail {
    font-size: 1.5rem;
    color: #aeaeae;
  }

  > div {
    width: 650px;

    .custom-radio {
      margin-left: 50px;
      margin-right: 30px;
    }

    @media (max-width: 991px) and (min-width: 768px) {
      & {
        width: 400px;

        .custom-radio {
          margin-left: 20px;
          margin-right: 10px;
        }
      }
    }

    @media (max-width: 767px) {
      & {
        width: 400px;

        .custom-radio {
          margin-left: 30px;
          margin-right: 20px;
        }
      }
    }
  }
}

.plan-tab-header {
  border-bottom: 2px solid #333333;
  font-size: 1rem;
  margin-top: 1.5rem !important;
}

.plan-tab-header > .plan-tab-header-item {
  padding: 5px 20px;
  cursor: pointer;
}

.plan-tab-header > .plan-tab-header-item.active {
  border-bottom: 4px solid #0090e7;
}

.plan-tab-header > .plan-tab-header-item > * {
  cursor: pointer;
}
